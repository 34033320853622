import React from "react";
// import { connect } from "react-redux";
import CineticaLogo from '../../img/sinapt_banner.png'
import { Link } from 'react-scroll'

class Header extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showMenu: '',
      collapsedMenu: 'collapsed'
    }

  }

  onMenuClick = (ev) => {
    this.setState(prevState => ({
      ...prevState,
      showMenu: prevState.showMenu === '' ? 'show' : '',
      collapsedMenu: prevState.collapsedMenu === '' ? 'collapsed' : ''
    }))
  }

  render() {
    const { showMenu, collapsedMenu } = this.state
    return (
      <React.Fragment>
        {/* start header section */}
        <header className="header">
          {/* <script src="../../assets/js/jquery-3.4.1.min.js"></script>
          <script src="../../assets/js/popper.min.js"></script>
          <script src="../../assets/js/bootstrap.min.js"></script>
          <script src="../../assets/js/jquery.magnific-popup.min.js"></script>
          <script src="../../assets/js/jquery.easing.min.js"></script>
          <script src="../../assets/js/owl.carousel.min.js"></script> */}
          {/* <script src="../../assets/js/scripts.js"></script> */}
          <script
            src="https://code.jquery.com/jquery-3.3.1.min.js"
            integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
            crossOrigin="anonymous"
          />
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={CineticaLogo}
                  width="180"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <button
                className={`navbar-toggler ${collapsedMenu}`}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.onMenuClick}
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className={`collapse navbar-collapse main-menu ${showMenu}`}
                id="navbarSupportedContent"
              >
             
              </div>
            </div>
          </nav>
        </header>
        {/* end header section */}
      </React.Fragment>
    );
  }
}

export default Header;
