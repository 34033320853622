import React from "react";
import _data from "../../data";
import bgImage from "../../img/hero-bg-real-4.png"
import { Link } from 'react-scroll'

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero7
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="hero-section ptb-100 background-img"
          style={{
            background: `center / cover no-repeat url(${bgImage})`,
            backgroundAttachment: 'fixed'
          }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-9 col-md-10 col-lg-7">
                <div className="hero-content-left text-white text-center ptb-100">
                  <h1 className="text-white">We are Sinapt</h1>
                  <p className="lead">Software ideas</p>

                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HeroSection;
