module.exports = {
  
    aboutUs: {
      title: "Who we are",
      description:
        "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
      items: [
        {
          title: "Strong solutions",
          description: "Display recent conversions, build credibility and trust.",
          icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
        },
        {
          title: "Latest technologies",
          description: "We are strongly experienced in .Net, Android and iOs"
        }
      ],
    },
    aboutUs2: {
      title: "We help your business move toward the future",
      description:
        "",
      image: "img/hero-bg-5.jpg",
      items: [
        {
          title: "Strong solutions",
          description: "Web and mobile development",
          longDescription:
            "",
          icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
        },
        {
          title: "Latest technologies",
          description: "We are experts in .Net, Android and iOs",
          longDescription:
            "",
          icon: "ti-stats-up rounded mr-3 icon icon-color-3"
        }
      ]
    },
    service2: {
      title: "What we do",
      description:
        "We help businesses thrive in this world of ever-changing technologies.",
      items: [
        {
          title: "Consultancy",
          description:
            "We use agile methodologies to convert your problems into strong solutions",
          icon: "ti-bar-chart icon-color-3 icon rounded",
          icon6: "ti-bar-chart icon-md color-secondary",
          image: "img/service7.jpg"
        },
        {
          title: "Software design and development",
          description:
            "We use the latest technologies to build robust and lasting software products",
          icon: "ti-desktop icon-color-1 icon rounded",
          icon6: "ti-desktop icon-md color-secondary",
          image: "img/service7.jpg"
        },
        {
          title: "Mobile app development",
          description:
            "Your business in your pocket at all times",
          icon: "ti-mobile icon-color-2 icon rounded",
          icon6: "ti-mobile icon-md color-secondary",
          image: "img/service5.jpg"
        },
      ]
    },
    contact: {
      title: "Contact us",
      description:
        "",
  
      office: "Head Office",
      address: "Montevideo, Uruguay",
      phone: "",
      email: "contact@sinapt.io"
    },
    hero: {
      title: "We are Sinapt Labs",
      description:
        "we do software",
      bgImage: "img/hero-bg-2.jpg",
      animations: [
        {
          className: "img-fluid d-block animation-one",
          src: "img/hero-animation-04.svg",
          width: ""
        },
        {
          className: "img-fluid d-none d-lg-block animation-two",
          src: "img/hero-animation-01.svg",
          width: "120"
        },
        {
          className: "img-fluid d-none d-lg-block animation-three",
          src: "img/hero-animation-02.svg",
          width: "120"
        },
        {
          className: "img-fluid d-none d-lg-block animation-four",
          src: "img/hero-animation-03.svg",
          width: "230"
        }
      ]
    },
    heroAboutUs: {
      title: "About Us",
      bgImage: "img/hero-bg-1.jpg"
    },
  };
  