import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import AboutSection from '../components/AboutSection'
import PromoSection from '../components/PromoSection'
import ServiceSection from '../components/Service'
import Contact from '../components/Contact';
import Footer from '../components/Footer';

import '../assets/css/bootstrap.min.css'
import '../assets/css/magnific-popup.css'
import '../assets/css/themify-icons.css'
import '../assets/css/owl.carousel.min.css'
import '../assets/css/owl.theme.default.min.css'
import '../assets/css/style.css'
import '../assets/css/responsive.css'

import $ from "jquery"

// import '//fonts.googleapis.com/css?family=Comfortaa:500,600,700%7COpen+Sans&display=swap'

class IndexPage extends React.Component {
  // <Layout>
  //   <SEO title="Home" />
  //   <h1>Hi people</h1>
  //   <p>Welcome to your new Gatsby site.</p>
  //   <p>Now go build something great.</p>
  //   <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
  //     <Image />
  //   </div>
  //   <Link to="/page-2/">Go to page 2</Link>
  // </Layout>

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // $(".navbar-nav li a").addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    // $(".navbar-nav li a").removeEventListener('click', this.handleClick)
  }

  handleScroll(ev) {
    if ($(this).scrollTop() > 60) {
      $('.navbar').addClass('affix');
    } else {
      $('.navbar').removeClass('affix');
    }
  }

  handleClick(ev) {
    if (!$(this).parent().hasClass('dropdown'))
      $(".navbar-collapse").collapse('hide');
  }

  render() {
    return <div>
      <Header />
      <div className="main">
        <HeroSection />
      </div>
      <Footer />
    </div>
  }
}

  export default IndexPage
