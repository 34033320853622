import React from "react";
// import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      promo: _data.promo
    });

    /**
     * import { postPromo } from "../../actions/index";
     *
     * After any action we can dispatch our data
     * // get action
     * const promoAction = postPromo(this.state);
     *
     * // Dispatch the promo data
     * this.props.dispatch(promoAction);
     *
     */
  }

  render() {
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }
}

export default PromoSection;
